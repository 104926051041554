.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.containerNav {
  background-color: #297abc;
  width: 100%;
  margin: 0px 25px;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 250px;
  }
}


.containerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  
  font-family: Roboto;
  


  > p {
    text-align: center;
  } 

}


.weight {
  font-weight: bold;
}





.containerForm {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px 10px;
  margin: 25px 25px;
  border: 1px solid rgb(199, 199, 199);
  box-shadow: 1px 1px 20px 3px #e7e7e7;

  > .containerItems{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 0px 0px 0px 0px;
    > img {
      width: 180px;
    }
  }

  > label {
    font-weight: bold;
    padding: 0px 0px 2.5px 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    }

  > input {
    width: auto;
    margin: 0px 15px 10px 15px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    box-shadow: inset 0px 0px 4px 0px #e7e7e7;
  }

  > p {
    font-size: 14px;
    text-align: center;
    padding: 0px 25px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: #9facb4;
  }

  #business_type {
    height: 37px;
    font-size: 15px;
    line-height: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width: 95.2%;
    border: 1px solid #e7e7e7;
    color: #000;
    margin: 10px 0px 10px 10px;
    }

  > div { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 10px 0px;

    > button {
    color: #fff;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 22px;
    background-color: #ec6923;
    cursor: pointer;
    }
  }

  > span {
    font-weight: bold;
    text-align: center;
    font-style: normal;
    font-family: Roboto;
    margin: 5px 0px 10px 0px;
  }


  > a {
    font-weight: bold;
    text-align: center;
    font-style: normal;
    font-family: Roboto;
    font-size: 12px;
    margin: 20px 0px 40px 0px;
    cursor: pointer;
    text-decoration: underline;
    color: #000;
  }
}

.containerFooter {
  border-top: 5px solid #7c8d9b;
  margin-top: 30px;
  height: 250px;
  background-color: #181515;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > img {
    width: 200px;
    padding-left: 30px;
  }

  > p {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    padding-left: 30px;
    margin-right: 30px;
  }

  > a {
    padding-left: 30px;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
  }
}


.Main-Model {
  position: fixed; /* Fixa o modal no viewport */
  top: 50%; /* Posiciona o modal no centro verticalmente */
  left: 50%; /* Posiciona o modal no centro horizontalmente */
  transform: translate(-50%, -50%); /* Desloca o modal para o centro */
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #fff;
  overflow: hidden;
  border: 1px solid rgb(199, 199, 199);
  box-shadow: 1px 1px 20px 3px #e7e7e7;
}


.Modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 20px 40px 40px;
  justify-content: center;

  > div:nth-child(1){ 
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;

    > h2 {
      font-family: Roboto;
    font-size: 18px;
    }

    > p {
      font-family: Roboto;
    font-size: 14px;
    }

  > video {
    max-width: 350px;
    border-radius: 5px;
  }
  }


  > div:nth-child(2){
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;

    > div:nth-child(1) { 
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 70%;
      justify-content: center;
      align-items: flex-end;
      > canvas {
      max-width: 500px;
      border-radius: 5px;
    }}

    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 30%;
      justify-content: space-around;
      align-items: flex-end;


      > button:first-child {
        color: #fff;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        font-size:14px;
        background-color: #297abc;
        cursor: pointer;
      }


      > button:nth-child(2) {
        color: #fff;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        font-size:14px;
        background-color: #ec6923;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Modal {
    padding: 10px 20px 20px 20px;
    flex-direction: column;
    > div:first-child{ 
      width: 100%;
      margin-bottom: 20px;
      > video {
        height: 150px;
        width: 250px;
        border-radius: 5px;
      }
      
  }

    > div:nth-child(2){
      width: 100%;

      > div:nth-child(1) { 
        margin-bottom: 20px;
        > canvas {
          height: 150px;
        width: 250px;
        border-radius: 5px;
      }}

      > div:nth-child(2) {
        > button:first-child {
          border-radius: 0;
          padding: 7.5px 15px;
        }
  
  
        > button:nth-child(2) {
          border-radius: 0;
          padding: 7.5px 15px;
 
        }
      }

    }
} }